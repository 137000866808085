import { PageProps } from "gatsby";
import * as React from "react";
import Api from "src/api";
import PageHeader from "src/components/PageHeader/PageHeader";
import Typography from "src/components/Typography";
import { CourseAnnounce, PaginationParams } from "src/types";
import classes from "./School.module.css";
import Announces from "./SchoolAnnounces";

type State = {
  announces: CourseAnnounce[];
  filters: {
    fieldOfStudy?: string;
  };
  pagination: {
    total: number;
    page: number;
    hasNextPage?: boolean;
    limit: number;
  };
};

class School extends React.Component<PageProps, State> {
  public state = {
    announces: [],
    filters: {
      fieldOfStudy: null,
    },
    pagination: {
      total: 0,
      page: 0,
      limit: 11,
      hasNextPage: true,
    },
  };

  public async componentDidMount() {
    await this.getData();
  }

  public handleFiltersChange = (value: any, type: string) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [type]: value,
      },
    });
  };

  public async componentDidUpdate(_, prevState: State) {
    if (prevState.filters.fieldOfStudy !== this.state.filters.fieldOfStudy) {
      await this.getData({}, { clean: true });
    }
  }

  public render() {
    return (
      <div>
        <PageHeader text="Школа" />
        <div className={classes.pageDescription}>
          <Typography type="text">
            Базовое онлайн-образование для сценаристов, режиссеров и продюсеров
            от продюсерского центра Potential c привлечением профессионалов
            индустрии.
          </Typography>
        </div>
        <Announces
          data={this.state.announces}
          handleChange={this.handleFiltersChange}
          pagination={this.state.pagination}
          getNewData={this.getData}
        />
      </div>
    );
  }

  private getData = async (query = {}, options: { clean?: boolean } = {}) => {
    const res = await Api.getSchoolAnnounces(
      this.state.filters,
      this.getReqData(query, options)
    );
    this.updateData(res.data, options);
  };

  private getReqData = (query = {}, options: { clean?: boolean }) => {
    if (options.clean) {
      return {
        page: 1,
        limit: this.state.pagination.limit,
        ...query,
      };
    }

    return {
      page: this.state.pagination.page ? this.state.pagination.page + 1 : 1,
      limit: this.state.pagination.limit,
      ...query,
    };
  };

  private updateData = (
    data: PaginationParams<CourseAnnounce>,
    options: { clean?: boolean } = {}
  ) => {
    if (options.clean) {
      this.setState({
        announces: data.data,
        pagination: {
          limit: this.state.pagination.limit,
          total: data.total || 0,
          page: data.page,
          hasNextPage: data.hasNextPage,
        },
      });
      return;
    }

    this.setState({
      announces: [...this.state.announces, ...data.data],
      pagination: {
        ...this.state.pagination,
        total: data.total || 0,
        page: data.page,
        hasNextPage: data.hasNextPage,
      },
    });
  };
}

export default School;
