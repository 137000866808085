import * as React from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import Typography from "src/components/Typography";
import { CourseAnnounce } from "src/types";
import classes from "./PaginationScroll.module.css";

interface Props {
  data: CourseAnnounce[];
  pagination: {
    total: number;
    page: number;
    hasNextPage?: boolean;
  };
  component(data: CourseAnnounce, i: number): JSX.Element;
  getNewData(): void;
}

const Scroll: React.SFC<Props> = (props) => {
  if (!props.data?.length) {
    return (
      <div
        style={{
          margin: "88px auto",
        }}
      >
        <Typography type="h3">Кажется, тут ничего нет</Typography>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={props.data.length} //This is important field to render the next data
      next={props.getNewData}
      hasMore={props.pagination.hasNextPage}
      loader={<Typography type="boldText">Загружаем</Typography>}
      className={classes.mappedAnnounces}
    >
      {props.data.map(props.component)}
    </InfiniteScroll>
  );
};

export default Scroll;
