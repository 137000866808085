import * as React from "react";
import Announce from "src/components/Announce/Announce";
import PGScroll from "src/components/PaginationScroll";
import { CourseAnnounce } from "src/types";
// import Filters from "./Filters";

interface Props {
  data: CourseAnnounce[];
  pagination: {
    total: number;
    page: number;
    hasNextPage?: boolean;
  };
  handleChange(v: any, type: string): void;
  getNewData(): void;
}

const SchoolAnnounces = (props: Props) => {
  return (
    <div>
      {/* <Filters handleChange={props.handleChange} /> */}
      <PGScroll {...props} component={renderComponent} />
    </div>
  );
};

const renderComponent = (curr: CourseAnnounce, i: number) => {
  const linkPrefix = curr.payedByUser ? "courses" : "about";
  return (
    <Announce
      key={i}
      announce={{
        img: curr.announce.img,
        announceDescription: curr.announce?.description,
        name: curr.name,
      }}
      link={`${linkPrefix}/${curr.latinName}`}
    />
  );
};

export default SchoolAnnounces;
