import { PageProps } from "gatsby";
import * as React from "react";
import Seo from "src/components/Seo";
import SchoolRoute from "src/routes/School";

const School: React.SFC<PageProps> = (props) => {
  return (
    <>
      <Seo
        pageTitle="Школа"
        pageDescription="Базовое онлайн-образование для сценаристов, режиссеров и продюсеров от
          продюсерского центра Potential c привлечением профессионалов
          индустрии"
      />
      <SchoolRoute {...props} />
    </>
  );
};

export default School;
